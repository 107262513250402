// src/About.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './about.css'; // Ensure this CSS file exists
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import transformToDelta from './utils/transformToDelta'
const BASE_URL = "https://africastrongcms.twnln.com/";

function About() {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const url = `${BASE_URL}/api/about?populate[comp_one][populate]=*&populate[comp_two][populate]=*&populate[comp_three][populate]=*`;

    axios.get(url)
      .then(response => {
        setAboutData(response.data.data.attributes);
        console.log(response.data.data.attributes)
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = error.response?.data?.message || error.message || 'Error fetching data';
        setError(errorMessage);
        setLoading(false);
      });
  }, []);

  if (loading) return <span class="loader"></span>;
  if (error) return <div>Error: {error}</div>; // Ensure error is a string

  const compOne = aboutData?.comp_one?.[0] || {};
  const compTwo = aboutData?.comp_two?.[0] || {};
  const compThree = aboutData?.comp_three?.[0] || {};

  return (
    <div className="about-container">

      <div className='headerBanner'>
        <div className='container'>
          <h1>{compOne.title}</h1>
        </div>
      </div>

      <div className='container'>



        <div className="about-details">
          {/* Render Comp One Data */}
          {compOne ? (

            <div className="comp-one-container">
              <div className="event-container" style={{ flexDirection: 'row' }}>

                <div className="media-container" style={{ flexDirection: 'row' }}>
                  {compOne.media?.data?.[0]?.attributes?.url && (
                    <img
                      src={`${BASE_URL}${compOne.media.data[0].attributes.url}`}
                      alt={compOne.media.data[0].attributes.alternativeText || 'Media One'}
                      className="media-image"
                    />
                  )}
                </div>
                <div className="p-container" >
                  <h2>{compOne.p_title}</h2>

                  <div
                    dangerouslySetInnerHTML={{ __html: compOne.ParagraphEditor }}
                  />


                </div>
              </div>
            </div>

          ) : (
            <div>No data available for Comp One</div>
          )}

          {/* Render Comp Two Data */}
          {compTwo ? (
            <div className="comp-two-container">
              <h1>{compTwo.title}</h1>
              <h2>{compTwo.p_title}</h2>

              <div
                dangerouslySetInnerHTML={{ __html: compTwo.ParagraphEditor }}
              />

              <div className="media-container-two">
                {compTwo.media_one?.data?.attributes?.url && (
                  <img
                    src={`${BASE_URL}${compTwo.media_one.data.attributes.url}`}
                    alt={compTwo.media_one.data.attributes.alternativeText || 'Media One'}
                    className="media-image-two"
                  />
                )}
                {compTwo.media_two?.data?.[0]?.attributes?.url && (
                  <img style={{ height: '267px' }}
                    src={`${BASE_URL}${compTwo.media_two.data[0].attributes.url}`}
                    alt={compTwo.media_two.data[0].attributes.alternativeText || 'Media Two'}
                    className="media-image-two"
                  />
                )}
                {compTwo.media_three?.data?.[0]?.attributes?.url && (
                  <img
                    src={`${BASE_URL}${compTwo.media_three.data[0].attributes.url}`}
                    alt={compTwo.media_three.data[0].attributes.alternativeText || 'Media Three'}
                    className="media-image-two"
                  />
                )}
              </div>
            </div>
          ) : (
            <div>No data available for Comp Two</div>
          )}

          {/* Render Comp Three Data */}
          {compThree ? (
            <div className="comp-three-container">
              <h1>{compThree.title}</h1>
              <div className="content-wrapper">
                <div className="p-container-two">
                  <h2>{compThree.p_title}</h2>

                  <div
                    dangerouslySetInnerHTML={{ __html: compThree.ParagraphEditor }}
                  />

                </div>
                <div className="media-container-three ">
                  {compThree.media?.data?.attributes?.url && (
                    <img style={{ width: '100%' }}
                      src={`${BASE_URL}${compThree.media.data.attributes.url}`}
                      alt={compThree.media.data.attributes.alternativeText || 'Media'}
                      className="media-image-three"
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>No data available for Comp Three</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default About;
