import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './our-team.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import transformToDelta from './utils/transformToDelta';


function Team() {
  const [error, setError] = useState('');
  const [teamData, setTeamData] = useState({
    co_founder: [],
    executive_director: [],
    board_of_directors: []
  });

  const fetchTeamData = async () => {
    try {
      const response1 = await axios.get('https://africastrongcms.twnln.com/api/our-teams/1?populate[co_founder][populate]=media');
      const response2 = await axios.get('https://africastrongcms.twnln.com/api/our-teams/2?populate[executive_director][populate]=media');
      const response3 = await axios.get('https://africastrongcms.twnln.com/api/our-teams/3?populate[board_of_directors][populate]=media');
       

      /*
      console.log('Co-Founders Data:', response1.data);
      console.log('Executive Directors Data:', response2.data);
      console.log('Board of Directors Data:', response3.data);
      */

      setTeamData({
        co_founder: response1.data?.data?.attributes?.co_founder || [],
        executive_director: response2.data?.data?.attributes?.executive_director || [],
        board_of_directors: response3.data?.data?.attributes?.board_of_directors || []
      });
    } catch (err) {
      setError();
      console.error(err);
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, []);

  // Medya verilerini işleyip döndürür
  const renderMedia = (media) => {
    if (media && media.data && media.data[0] && media.data[0].attributes) {
      const mediaAttributes = media.data[0].attributes;
      const formats = mediaAttributes.formats || {};
      const format = formats.medium || formats.large || formats.small || formats.thumbnail;

      if (format && format.url) {
        return (
          <div className='photo-container-respon'>
            <img
              src={`https://africastrongcms.twnln.com/${format.url}`}
              alt={mediaAttributes.alternativeText || 'Media'}
              width={format.width}
              height={format.height}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        );
      }
    }
    return <p></p>;
  };

  return (
    <div className="team-container">

      <div className='headerBanner'>
        {teamData.co_founder.length > 0 ? (
          <div className='container'>
            {teamData.co_founder.map((coFounder) => (
              <h1 key={coFounder.id}>{coFounder.title}</h1>
            ))}
          </div>
        ) : null}
      </div>

      <div className='container'>


        <div className="team-details">


          {error && <p style={{ color: 'red' }}>{error}</p>}

          {/* Co-Founders */}
          {teamData.co_founder.length > 0 && (
            <>

              <section className='comp-one'>
                {teamData.co_founder.map((coFounder) => (
                  <div key={coFounder.id} className='cofounder-container'>


                    <div className='photo-cv-wrapper'>
                      {coFounder.media && coFounder.media.data && coFounder.media.data.attributes && (
                        <div className='photo-container'>

                          <img
                            src={`https://africastrongcms.twnln.com/${coFounder.media.data.attributes.url}`}
                            alt={coFounder.name}

                          />
                        </div>
                      )}
                      <div className='cv-container'>
                        <div className='cv-title'>
                          <h2>{coFounder.job_position}</h2>
                          <h3>{coFounder.name}</h3>
                        </div>

                        <ReactQuill
                          value={transformToDelta(coFounder.cv)}
                          readOnly={true}
                          theme="bubble"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            </>
          )}

          {/* Executive Directors */}
          {teamData.executive_director.length > 0 && (
            <section className='comp-one'>
              {teamData.executive_director.map((executiveDirector) => (
                <div key={executiveDirector.id} className='cofounder-container'>
                  <div className='photo-cv-wrapper'>
                    {executiveDirector.media && executiveDirector.media.data && executiveDirector.media.data.attributes && (
                      <div className='photo-container'>

                        <img
                          src={`https://africastrongcms.twnln.com/${executiveDirector.media.data.attributes.url}`}
                          alt={executiveDirector.name}
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      </div>
                    )}
                    <div className='cv-container'>
                      <div className='cv-title'>
                        <h2 >{executiveDirector.job_position}</h2>
                        <h3>{executiveDirector.name}</h3>
                      </div>
                      <ReactQuill
                        value={transformToDelta(executiveDirector.cv)}
                        readOnly={true}
                        theme="bubble"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </section>

          )}

          {/* Board of Directors */}
          {teamData.board_of_directors.length > 0 && (
            <section className='comp-one'>

              {teamData.board_of_directors.map((boardMember) => (

                <div className='cofounder-container' key={boardMember.id}>

                  <div className='photo-cv-wrapper'>
                    <div className='photo-container-board'>

                      {renderMedia(boardMember.media)}
                    </div>
                    <div className='cv-container'>
                      <div className='cv-title'>
                        {boardMember.title && (<h2>{boardMember.title}</h2>)}
                        <h3>{boardMember.name}</h3>
                      </div>
                      <ReactQuill
                        value={transformToDelta(boardMember.cv)}
                        readOnly={true}
                        theme="bubble"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </section>

          )}
        </div>
      </div>
    </div>
  );
}

export default Team;
