import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './projects.css';

function ProjectsMain() {
  const [error, setError] = useState('');
  const [mainPageProjects, setMainPageProjects] = useState([]);

  // Fetch main page projects
  const fetchMainPageProjects = async () => {
    try {
      const response = await axios.get('https://africastrongcms.twnln.com/api/projects?populate=*');
      const projectItems = response.data?.data || [];

      // Filter projects where MainPage is true
      const filteredProjects = projectItems.filter(project => project.attributes.MainPage === true);
      const sortedProjects = filteredProjects.sort((a, b) => a.id - b.id);

      console.log("mainPageProjects", sortedProjects);

      setMainPageProjects(sortedProjects);
    } catch (err) {
      setError('fetching error.');
      console.error(err);
    }
  };

  useEffect(() => {
    fetchMainPageProjects();
  }, []);

  // Function to split projects into rows
  const splitProjectsIntoRows = (projectList, projectsPerRow) => {
    const rows = [];
    for (let i = 0; i < projectList.length; i += projectsPerRow) {
      rows.push(projectList.slice(i, i + projectsPerRow));
    }
    return rows;
  };

  return (
    <>
      <div className='titleStyle'>
        <h2> {mainPageProjects[0]?.attributes?.project_categories.data[0].attributes?.Title} </h2>
        <p> {mainPageProjects[0]?.attributes?.project_categories.data[0].attributes?.SubTitle}  </p>
      </div>

      <div className="container">
        <div className="projects-container">
          {error ? (
            <p>{error}</p>
          ) : (
            <>
              <div className="main-page-projects">

                {mainPageProjects?.length ? (
                  splitProjectsIntoRows(mainPageProjects, 3).map((row, rowIndex) => (
                    <div key={rowIndex} className="project-row">
                      {row.map(project => {
                        const { slug, title, media } = project.attributes;
                        const imageUrl = media?.data?.[0]?.attributes?.formats?.medium?.url || media?.data?.[0]?.attributes?.url;

                        return (
                          <div key={project.id} className="project-item">
                            {imageUrl && (
                              <a href={`/projects/${slug}`} className="project-link">
                                <img src={`https://africastrongcms.twnln.com/${imageUrl}`} alt={title} className="project-image" />
                              </a>
                            )}
                            <a href={`/projects/${slug}`}>
                              <p className="project-title">
                                {title}
                              </p>
                            </a>
                            <a href={`/projects/${slug}`} className="project-icon">
                              <img src="https://africastrongcms.twnln.com//uploads/project_icon_da395526ee.png" alt="View Project" />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ))
                ) : (
                  <span className="loader"></span>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ProjectsMain;
