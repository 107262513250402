import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './program.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import transformToDelta from './utils/transformToDelta';


//components
import Popup from "../src/Popup";


const BASE_URL = "https://africastrongcms.twnln.com/";

function Program() {
  const [programData, setProgramData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allProjects, setAllProjects] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const url = `${BASE_URL}/api/program?populate[comp_one][populate]=*&populate[comp_two][populate]=*&populate[comp_three][populate]=*&populate[comp_three_list][populate]=*`;

    axios.get(url)
      .then(response => {
        console.log('API Response:', response.data);
        setProgramData(response.data.data.attributes);
        setLoading(false);
      })
      .catch(error => {
        console.error('API Error:', error.response || error);
        const errorMessage = error.response?.data?.message || error.message || 'Error fetching data';
        setError(errorMessage);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchAllProjects();
  }, []);

  // Fetch all projects
  const fetchAllProjects = async () => {
    try {
      const response = await axios.get('https://africastrongcms.twnln.com/api/projects?populate=*');
      const projectItems = response.data?.data || [];

      // Sort projects by ID
      const sortedProjects = projectItems.sort((a, b) => a.id - b.id);

      console.log("sortedProjects", sortedProjects);

      setAllProjects(sortedProjects);
    } catch (err) {
      setError('fetching error.');
      console.error(err);
    }
  };

  const splitProjectsIntoRows = (projectList, projectsPerRow) => {
    const rows = [];
    for (let i = 0; i < projectList.length; i += projectsPerRow) {
      rows.push(projectList.slice(i, i + projectsPerRow));
    }
    return rows;
  };

  const compOne = programData?.comp_one || [];
  const compTwo = programData?.comp_two || [];
  const compThree = programData?.comp_three || [];

  return (
    <div className="program-container">
      {loading ? (
        <span className="loader"></span>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <>
          <div className='headerBanner'>
            {compOne.length > 0 ? (
              <div className='container'>
                {compOne.map((item, index) => (
                  <h1 key={index}>{item.title}</h1>
                ))}
              </div>
            ) : null}
          </div>

          <div className='container'>
            <div className="program-details">

              {/* Component One */}
              <div className="program-comp-one">
                {compOne.length > 0 ? (
                  compOne.map((item, index) => (
                    <div key={index} className="program-comp-one-item">
                      <h2>{item.video_title}</h2>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.ParagraphEditor
                        }}></div>

                      {item.video_link && (
                        <>
                          <a className='trailerBtn' onClick={openModal}> <i><img src='/icons/playBtn.svg' /></i>  {item.videoButtonText} </a>
                          <Popup show={isModalOpen} onClose={closeModal} videoSrc={`${item.video_link}?autoplay=1`} />
                        </>
                      )}


                    </div>
                  ))
                ) : (
                  <div>No data available for Component One</div>
                )}
              </div>

              {/* Component Two */}
              <div className="program-comp-two">
                {compTwo.length > 0 ? (
                  compTwo.map((item) => (
                    <div key={item.id} className="program-item">

                      <div className='program-item-text'>
                        <h3>{item.title}</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.ParagraphEditor
                          }}></div>

                      </div>


                      <div className="media-container">
                        {item.media?.data && (
                          <img
                            src={`${BASE_URL}${item.media.data.attributes.url}`}
                            alt={item.media.data.attributes.alternativeText || 'Media'}
                            className="media-image"
                          />
                        )}
                      </div>




                    </div>
                  ))
                ) : (
                  <div>No data available for Component Two</div>
                )}
              </div>

              {/* Component Three */}
              <div className="program-comp-three">
                {compThree.length > 0 ? (
                  compThree.map((item, index) => (
                    <div key={index} className="program-comp-three-item">
                      <h2>{item.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.ParagraphEditor
                        }}></div>
                    </div>
                  ))
                ) : (
                  <div>No data available for Component Three</div>
                )}
              </div>
            </div>
          </div>

          <div className='container'>
            {error ? (
              <p>{error}</p>
            ) : (
              <div className="all-projects">

                <div className='project-galleries-container'>
                  <h5> {allProjects[0]?.attributes.project_categories.data[0].attributes.Title} </h5>
                  <p> {allProjects[0]?.attributes.project_categories.data[0].attributes.SubTitle}  </p>
                </div>

                {allProjects.length ? (
                  splitProjectsIntoRows(allProjects, 3).map((row, rowIndex) => (
                    <div key={rowIndex} className="project-row">
                      {row.map(project => {
                        const { slug, title, media } = project.attributes;
                        const imageUrl = media?.data?.[0]?.attributes?.formats?.medium?.url || media?.data?.[0]?.attributes?.url;

                        return (
                          <div key={project.id} className="project-item">
                            {imageUrl && (
                              <a href={`/projects/${slug}`} className="project-link">
                                <img src={`https://africastrongcms.twnln.com/${imageUrl}`} alt={title} className="project-image" />
                              </a>
                            )}
                            <a href={`/projects/${slug}`}>
                              <p className="project-title">{title}</p>
                            </a>
                            <a href={`/projects/${slug}`} className="project-icon">
                              <img src="https://africastrongcms.twnln.com//uploads/project_icon_da395526ee.png" alt="View Project" />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ))
                ) : (
                  <span className="loader"></span>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>

  );
}

export default Program;

