import React, { useState, useEffect } from 'react';
import './contact.css';

//map api
import Map from "./contact-map.js";

function Contact() {


  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('https://africastrongcms.twnln.com/api/contact')
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "data");
        setData(data.data.attributes); // API'den gelen veriyi state'e kaydediyoruz
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);




  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { email, name, message } = formData;

    if (!email) {
      alert('Please enter an email address');
      return;
    }

    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address');
      return;
    }

    fetch('https://africastrongcms.twnln.com/api/feedbacks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          name,
          email,
          message,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(`${name}'s contact message:`, data); // API yanıtını kontrol edin
        alert(`Thanks, ${name}! We have received your message.`);
        setFormData({ name: '', email: '', message: '' });
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Error, try again.');
      });
  };

  return (
    <>
      <div className='headerBanner'>
        <div className='container'>
          <h1>Contact Us</h1>
        </div>
      </div>

      <div className='container'>
        <div className='contactRow'>


          <div className='contactInner'>

            <div className='contactMap'>
              {data?.GMapLocation?.coordinates?.lat && data?.GMapLocation?.coordinates?.lng && <Map lat={data?.GMapLocation?.coordinates?.lat} lng={data?.GMapLocation?.coordinates?.lng} />
              }
            </div>

            <div className='contactInfo'>
              <ul>
                {data && (
                  <>
                    {data.Phone && (
                      <li>
                        <a href={`tel:${data.Phone}`}>
                          <i> <img src='/icons/phone.svg' /> </i> {data.Phone}
                        </a>
                      </li>
                    )}

                    {data.Mail && (
                      <li>
                        <a href={`mailto:${data.Mail}`}>
                          <i> <img src='/icons/mail.svg' /> </i> <span>{data.Mail}</span>
                        </a>
                      </li>
                    )}
                    {data.GMapLocation.address && (
                      <li>
                        <a>
                          <i> <img src='/icons/address.svg' /> </i>
                          <span>{data.GMapLocation.address}</span>
                        </a>
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>


          </div>


          <div className="contact-container">

            {data?.FormTitle && (
              <div className='formTitle'>
                <strong> <i> <img src='/icons/hearts.svg' /> </i> {data?.FormTitle} </strong>
                <p>  {data?.FormDesc} </p>
              </div>
            )}


            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                />
              </div>
              <div className="input-group">
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
              </div>
              <div className="input-group">
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                />
              </div>
              <button type="submit" className="submit-button">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
