import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './event.css';
import transformToDelta from './utils/transformToDelta'


const BASE_URL = "https://africastrongcms.twnln.com/";

function Event() {
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const url = `${BASE_URL}/api/event?populate[comp][populate]=*`;

    axios.get(url)
      .then(response => {
        setEventData(response.data.data.attributes.comp || []);
        setLoading(false);
      })
      .catch(err => {
        console.error('API Error:', err.response || err);
        setError(err.response?.data?.message || err.message || 'Error fetching data');
        setLoading(false);
      });
  }, []);

  if (loading) return <span class="loader"></span>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="event-container">
      <div className='headerBanner'>
        <div className='container'>
          <h1>Events</h1>
        </div>
      </div>

      {eventData.length > 0 ? (
        eventData.map((comp, index) => (
          <div className='container'>

            <div className="event-details" key={index}>
              {comp.title && (
                <h2>{comp.title}</h2>)}

              <div
                dangerouslySetInnerHTML={{ __html: comp?.ParagraphEditor }}
              />

              {comp.youtube_video_link && (
                <div className="video-container">
                  <iframe
                    width="610"
                    height="352"
                    src={comp.youtube_video_link}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
              )}

              <p>{comp.paragraph || ''}</p>



              {comp.photo && comp.photo.data && (
                <div className="photo-container">
                  <img
                    src={comp.photo.data.attributes.url}
                    alt=""
                    className="event-photo"
                  />
                </div>
              )}

              <div className="button-container">
                {comp.buy_tickets_link && (
                  <a href={comp.buy_tickets_link} target="_blank" rel="noopener noreferrer">
                    <button className="event_button">Buy Tickets</button>
                  </a>
                )}

                {comp.collaborative_page_link && (
                  <a href={comp.collaborative_page_link} target="_blank" rel="noopener noreferrer">
                    <button className="event_button">Collaborative Web Page</button>
                  </a>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
}

export default Event;
