import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './home.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Blog from './blog'
import MainBlog from './blogMain';
import Projects from './projects'
import ProjectsMain from './projectsMain';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import transformToDelta from './utils/transformToDelta';


function Home() {
  const [homeData, setHomeData] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    axios.get('https://africastrongcms.twnln.com/api/home', {
      params: {
        'populate[main][populate][0]': 'media',
        'populate[main][populate][1]': 'media_two',
        'populate[counter][populate][0]': 'charitable_logo',
        'populate[counter][populate][1]': 'students_logo',
        'populate[counter][populate][2]': 'teachers_logo',
        'populate[counter][populate][3]': 'projects_logo',
        'populate[partners_sponsors][populate]': '*',
        'populate[footer][populate]': '*',
        'populate[catch_sentence][populate][0]': 'sentence',
      }
    })
      .then(response => {
        setHomeData(response.data.data.attributes);
      })
      .catch(error => {
        console.error('Error fetching home data:', error);
        setError('Error fetching home data');
      });
  }, []);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!homeData) {
    return <span className="loader"></span>;
  }

  return (
    <div className="home">
      {homeData.main && homeData.main.length > 0 && (
        <div className="main">
          <Main slidesData={homeData.main} />
        </div>
      )}
      {homeData.counter ? (
        <div className="counter">
          <Counter data={homeData.counter} />
        </div>
      ) : (
        <div className="error-message">Error fetching counter data</div>
      )}
      <div className='project-galleries sectSpacing'>
     
        <ProjectsMain />
      </div>
      <div className='lastest-news sectSpacing'>
        <div className='titleStyle'>
          <h2> Lastest News</h2>
          <p>Update</p>
        </div>
        <MainBlog />
      </div>
      {homeData.partners_sponsors ? (
        <div className="partners-sponsors sectSpacing">
          <PartnersSponsors data={homeData.partners_sponsors} />
        </div>
      ) : (
        <div className="error-message">Error fetching partners and sponsors data</div>
      )}
    </div>
  );
}

// Önceki ok bileşeni
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`custom-prev-arrow ${className}`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

// Sonraki ok bileşeni
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`custom-next-arrow ${className}`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const Main = ({ slidesData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: slidesData.length > 1,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: slidesData.length > 1 ? <SamplePrevArrow /> : null,
    nextArrow: slidesData.length > 1 ? <SampleNextArrow /> : null,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  if (!slidesData || slidesData.length === 0) {
    return <div>No slides available</div>;
  }

  const renderSlideContent = (slide, index) => (
    <div className="slide" key={index}>
      <div className="container">
        <div className="text-content">
          <h1 className="main-title">{slide.title || 'No Title'}</h1>
          <p className="main-paragraph">
            <ReactQuill
              value={transformToDelta(slide.paragraph)}
              readOnly={true}
              theme="bubble"
            />
          </p>
        </div>
        <div className="slider-image-container">
          {slide.media && slide.media.data && slide.media.data.attributes && (
            <img
              src={`https://africastrongcms.twnln.com/${slide.media.data.attributes.url}`}
              alt={`Slide ${index}`}
              className="main-image"
            />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slidesData.map((slide, index) => renderSlideContent(slide, index))}
      </Slider>
      <div className='container'>


        <div className="dots-container">
          {slidesData.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};


function Counter({ data }) {
  const baseUrl = 'https://africastrongcms.twnln.com/';

  if (!data || !Array.isArray(data)) {
    return <div>Data not found</div>;
  }

  return (
    <div className='container'>
      <div className='counter-container'>
        {data.map((item, index) => {
          const imageUrl = `${baseUrl}${item.teachers_logo?.data?.attributes?.url}`;
          const studentsLogoUrl = `${baseUrl}${item.students_logo?.data?.attributes?.url}`;
          const projectsLogoUrl = `${baseUrl}${item.projects_logo?.data?.attributes?.url}`;
          const charitableLogoUrl = `${baseUrl}${item.charitable_logo?.data?.attributes?.url}`;

          return (
            <div className='counter-box' key={index}>
              <div className='counter-item'>
                <div className='counter-icon'>
                  {projectsLogoUrl && (
                    <img src={projectsLogoUrl} alt="projects_logo" />
                  )}
                </div>
                <p className='counter-number'>{item.projects_completed}</p>
                <p className='counter-label'>Projects Completed</p>
              </div>

              <div className='counter-item'>
                <div className='counter-icon'>
                  {imageUrl && (
                    <img src={imageUrl} alt="teachers_logo" />
                  )}
                </div>
                <p className='counter-number'>{item.teachers_impacted}</p>
                <p className='counter-label'>Teachers Impacted</p>
              </div>

              <div className='counter-item'>
                <div className='counter-icon'>
                  {studentsLogoUrl && (
                    <img src={studentsLogoUrl} alt="students_logo" />
                  )}
                </div>
                <p className='counter-number'>{item.students_impacted}</p>
                <p className='counter-label'>Students Impacted</p>
              </div>

              <div className='counter-item'>
                <div className='counter-icon'>
                  {charitableLogoUrl && (
                    <img src={charitableLogoUrl} alt="charitable_logo" />
                  )}
                </div>
                <p className='counter-number'>{item.charitable_donations}</p>
                <p className='counter-label'>Charitable Donations</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function PartnersSponsors({ data }) {
  return (
    <div className="container">
      <div className="partners-sponsors-container">
        <div className='titleStyle'>
          <h2>Partners And Sponsors</h2>
        </div>
        <div className="partners-sponsors-block-wrapper">
          {data.map((sponsor, index) => (
            sponsor.sponsor?.data?.attributes?.url && (
              <div key={index} className="partners-sponsors-block">
                <img
                  src={`https://africastrongcms.twnln.com/${sponsor.sponsor.data.attributes.url}`}
                  alt={`Partner ${index + 1}`}
                  className={`partner-image`}
                />
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
}


export default Home;
