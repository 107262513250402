import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './projects.css';

function Projects() {
  const [error, setError] = useState('');
  const [allProjects, setAllProjects] = useState([]);

  // Fetch all projects
  const fetchAllProjects = async () => {
    try {
      const response = await axios.get('https://africastrongcms.twnln.com/api/projects?populate=media');
      const projectItems = response.data?.data || [];

      // Sort projects by ID
      const sortedProjects = projectItems.sort((a, b) => a.id - b.id);

      setAllProjects(sortedProjects);
    } catch (err) {
      setError('fetching error.');
      console.error(err);
    }
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  // Function to split projects into rows
  const splitProjectsIntoRows = (projectList, projectsPerRow) => {
    const rows = [];
    for (let i = 0; i < projectList.length; i += projectsPerRow) {
      rows.push(projectList.slice(i, i + projectsPerRow));
    }
    return rows;
  };

  return (
    <div className="projects-container">

      <div className='headerBanner'>
        <div className='container'>
          <h1> Projects </h1>
        </div>
      </div>


      <div className='container'>


        {error ? (
          <p>{error}</p>
        ) : (
          <>
            <div className="all-projects">
              {allProjects.length ? (
                splitProjectsIntoRows(allProjects, 3).map((row, rowIndex) => (
                  <div key={rowIndex} className="project-row">
                    {row.map(project => {
                      const { slug, title, media } = project.attributes;
                      const imageUrl = media?.data?.[0]?.attributes?.formats?.medium?.url || media?.data?.[0]?.attributes?.url;

                      return (
                        <div key={project.id} className="project-item">
                          {imageUrl && (
                            <a href={`/projects/${slug}`} className="project-link">
                              <img src={`https://africastrongcms.twnln.com/${imageUrl}`} alt={title} className="project-image" />
                            </a>
                          )}
                          <a href={`/projects/${slug}`}>
                            <p className="project-title">
                              {title}
                            </p>
                          </a>
                          <a href={`/projects/${slug}`} className="project-icon">
                            <img src="https://africastrongcms.twnln.com//uploads/project_icon_da395526ee.png" alt="View Project" />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                ))
              ) : (
                <span className="loader"></span>
              )}
            </div>
          </>
        )}
      </div>
    </div >
  );
}

export default Projects;
