import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './home.css';

const BASE_URL = "https://africastrongcms.twnln.com";

function Navbar() {
  const [navbarData, setNavbarData] = useState(null);
  const [navMenuData, setNavMenuData] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openMenuId, setOpenMenuId] = useState(null);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/navbar?populate=*`)
      .then(response => {
        setNavbarData(response.data.data.attributes);
      })
      .catch(error => {
        console.error('Navbar fetching error:', error);
      });

    axios.get(`${BASE_URL}/api/nav-menus?populate=*`)
      .then(response => {
        setNavMenuData(response.data.data);
      })
      .catch(error => {
        console.error('Nav-Menus fetching error:', error);
      });
  }, []);

  const handleDonateClick = () => {
    window.location.href = 'https://www.paypal.com/donate/?hosted_button_id=YZR4RNGSHWM2W';
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const toggleSubMenu = (id) => {
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const getImageUrl = (data) => {
    return data?.attributes?.url ? `${BASE_URL}${data.attributes.url}` : '';
  };

  const renderNavMenu = () => {
    if (!navMenuData) return null;
  
    const sortedMenuData = navMenuData.sort((a, b) => a.id - b.id);
    const currentPath = window.location.pathname; // Aktif URL'yi alıyoruz
  
    return sortedMenuData.map((menu) => {
      if (menu.attributes.isMain) {
        const subMenus = menu.attributes.nav_menus.data;
        const isActive = currentPath === `/${menu.attributes.slugName}`; // Aktif menü kontrolü
        const hasSubMenuClass = subMenus.length > 0 ? 'has-submenu' : '';
        const isOpen = openMenuId === menu.id ? 'open' : '';
  
        return (
          <li className={`menu-item ${hasSubMenuClass} ${isOpen} `}  key={menu.id}>
            <div className='menutoggle-item' onClick={() => toggleSubMenu(menu.id)}>
              <a
                href={menu.attributes.slugName ? `/${menu.attributes.slugName}` : 'javascript:void(0)'}
                className={`menu-link ${isActive ? 'active' : ''} ${hasSubMenuClass}`}
              >
                {menu.attributes.MenuName}
              </a>
            </div>
            {subMenus.length > 0 && (
              <div className='dropdown-menu'>

              
              <ul>
                {subMenus.map((subMenu) => {
                  const isSubMenuActive = currentPath === `/${subMenu.attributes.slugName}`; // Aktif alt menü kontrolü
                  return (
                    <li key={subMenu.id}>
                      <a
                        href={`/${subMenu.attributes.slugName}`}
                        className={`menu-link ${isSubMenuActive ? 'active' : ''}`}
                      >
                        {subMenu.attributes.MenuName}
                      </a>
                    </li>
                  );
                })}
              </ul>
              </div>

            )}
          </li>
        );
      }
      return null;
    });
  };
  

  if (!navbarData) return <div></div>;

  return (
    <>
      <header>
        <nav className='navbar'>
          <div className='container'>
            <div className='navbar-brand'>
              {navbarData.logo && (
                <a href="/">
                  <img src={getImageUrl(navbarData.logo.data)} alt="Logo" />
                </a>
              )}
              <div className='navbar-brand-text'>
                <a href="/">{navbarData.title}</a>
                <div className='navbar-sentence'>
                  {navbarData.title_catch_sentence}
                </div>
              </div>
            </div>
            <ul className='navbar-nav'>
              {renderNavMenu()}
            </ul>
            <div className='sm-buttons'>
              {navbarData.insta_button && (
                <a target="_blank" rel="noopener noreferrer" className='sm-button' href='https://www.instagram.com/africastronginc/'>
                  <img style={{ width: '18.33px' }} src={getImageUrl(navbarData.insta_button.data)} alt="I" />
                </a>
              )}
              {navbarData.linkedin_button && (
                <a target="_blank" rel="noopener noreferrer" className='sm-button' href='https://www.linkedin.com/company/africastronginc'>
                  <img style={{ width: '17.11px' }} src={getImageUrl(navbarData.linkedin_button.data)} alt="L" />
                </a>
              )}
              {navbarData.facebook_button && (
                <a target="_blank" rel="noopener noreferrer" className='sm-button' href='https://www.facebook.com/africastronginc'>
                  <img style={{ width: '8.56px', height: '15.89px' }} src={getImageUrl(navbarData.facebook_button.data)} alt="F" />
                </a>
              )}
              {navbarData.youtube_button && (
                <a target="_blank" rel="noopener noreferrer" className='sm-button' href='https://www.youtube.com/@africastrong'>
                  <img style={{ width: '22px', height: '15.89px' }} src={getImageUrl(navbarData.youtube_button.data[0])} alt="Y" />
                </a>
              )}
            </div>
            <button onClick={handleDonateClick} className='donate-buttonn'>
              <img src={getImageUrl(navbarData.button_logo.data)} alt="Donate Now" />
              <div className='small-text'>Donate</div>
              <div className='large-text'>{navbarData.button}</div>
            </button>
            <div className='menu-toggle' onClick={toggleMenu}>
              <div className='bar'></div>
              <div className='bar'></div>
              <div className='bar'></div>
            </div>
          </div>
        </nav>
      </header>

      {isMenuOpen && (
        <div className='toggle-menu'>
          <div className='navbar-container-tog'>
            <div className='sm-buttons-tog'>
              {navbarData.insta_button && (
                <a target="_blank" rel="noopener noreferrer" className='sm-button' href='https://www.instagram.com/africastronginc/'>
                  <img style={{ width: '18.33px' }} src={getImageUrl(navbarData.insta_button.data)} alt="I" />
                </a>
              )}
              {navbarData.linkedin_button && (
                <a target="_blank" rel="noopener noreferrer" className='sm-button' href='https://www.linkedin.com/company/africastronginc'>
                  <img style={{ width: '17.11px' }} src={getImageUrl(navbarData.linkedin_button.data)} alt="L" />
                </a>
              )}
              {navbarData.facebook_button && (
                <a target="_blank" rel="noopener noreferrer" className='sm-button' href='https://www.facebook.com/africastronginc'>
                  <img style={{ width: '8.56px', height: '15.89px' }} src={getImageUrl(navbarData.facebook_button.data)} alt="F" />
                </a>
              )}
              {navbarData.youtube_button && (
                <a target="_blank" rel="noopener noreferrer" className='sm-button' href='https://www.youtube.com/@africastrong'>
                  <img style={{ width: '22px', height: '15.89px' }} src={getImageUrl(navbarData.youtube_button.data[0])} alt="Y" />
                </a>
              )}
            </div>
            <ul className='navbar-nav-tog'>
              {renderNavMenu()}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
