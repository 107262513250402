import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './blog-detail.css'; // CSS dosyasını içe aktar
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import transformToDelta from './utils/transformToDelta';

function BlogDetail() {
  const { slug } = useParams(); // useParams ile slug'ı alıyoruz
  const [error, setError] = useState('');
  const [blogData, setBlogData] = useState(null);
  const [comments, setComments] = useState([]);
  const [author, setAuthor] = useState('');
  const [email, setEmail] = useState('');
  const [commentText, setCommentText] = useState('');
  const [blogId, setBlogId] = useState(null);

  // Tüm blogları çekme ve slug ile id eşleştirme
  const fetchBlogIdBySlug = async () => {
    try {
      const response = await axios.get('https://africastrongcms.twnln.com/api/blogs');
      const blogs = response.data?.data || [];

      // URL'den gelen slug'ı blog slug'ları ile eşleştir
      const matchedBlog = blogs.find(blog => blog.attributes.slug === slug);

      if (matchedBlog) {
        setBlogId(matchedBlog.id);
      } else {
        setError('Blog bulunamadı.');
      }
    } catch (err) {
      setError('Blog ID çekilirken bir hata oluştu.');
      console.error(err);
    }
  };

  // Blog verilerini getiren fonksiyon
  const fetchBlogDetail = async () => {
    try {
      const response = await axios.get(`https://africastrongcms.twnln.com/api/blogs/${blogId}?populate[comp][populate]=media&populate[buttons][populate]=*`);
      setBlogData(response.data?.data);
    } catch (err) {
      setError('Blog verisi çekilirken bir hata oluştu.');
      console.error(err);
    }
  };

  // Yorumları getiren fonksiyon
  const fetchComments = async () => {
    try {
      const response = await axios.get(`https://africastrongcms.twnln.com/api/blogs/${blogId}?populate=comment`);
      setComments(response.data?.data?.attributes?.comment || []);
    } catch (err) {
      setError('Yorumlar çekilirken bir hata oluştu.');
      console.error(err);
    }
  };

  // Yeni yorum ekleme fonksiyonu
  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const blogResponse = await axios.get(`https://africastrongcms.twnln.com/api/blogs/${blogId}?populate=comment`);
      const blogData = blogResponse.data?.data;

      const existingComments = blogData?.attributes?.comment || [];
      const newComment = {
        name: author,
        email: email,
        comment: commentText,
      };
      const updatedComments = [...existingComments, newComment];

      await axios.put(`https://africastrongcms.twnln.com/api/blogs/${blogId}`, {
        data: {
          comment: updatedComments,
        },
      });

      setComments(updatedComments);
      setAuthor('');
      setEmail('');
      setCommentText('');
    } catch (err) {
      setError('Yorum gönderilirken bir hata oluştu.');
      console.error(err);
    }
  };

  useEffect(() => {
    fetchBlogIdBySlug(); // İlk olarak blog ID'sini bul
  }, [slug]);

  useEffect(() => {
    if (blogId) {
      fetchBlogDetail();
      fetchComments();
    }
  }, [blogId]);

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!blogData) {
    return <span className="loader"></span>;
  }

  const { attributes } = blogData;
  const comp = attributes?.comp?.[0];
  const mediaData = comp?.media?.data;
  const buttons = attributes?.buttons || [];

  return (
    <>
      <div className='headerBanner'>
        <div className='container'>
          <h1> {attributes.title} </h1>

          <ul className="breadcrumb">
            <li><a href="/blog">Blog</a></li>
            <li>{attributes?.title}</li>
          </ul>

        </div>
      </div>

      <div className='container'>
        <div className="blog-detail-container">
          {comp?.youtube_video_link && (
            <iframe
              width="610"
              height="350"
              src={comp?.youtube_video_link}
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          )}

          <div
            dangerouslySetInnerHTML={{ __html: comp.ParagraphEditor }}
          />

          {mediaData && (
            <div>
              {Array.isArray(mediaData) ? (
                mediaData.map((media) => (
                  <div key={media.id}>
                    <img
                      src={`https://africastrongcms.twnln.com/${media.attributes.url}`}
                      alt={media.attributes.alternativeText || 'Medya'}
                    />
                  </div>
                ))
              ) : (
                <img
                  src={`https://africastrongcms.twnln.com/${mediaData.attributes.url}`}
                  alt={mediaData.attributes.alternativeText || 'Medya'}
                />
              )}
            </div>
          )}

          {buttons.length > 0 && (
            <div className="buttons-section">
              <h2>Related Links</h2>
              <div className='button-container'>
                {buttons.map((button) => (
                  <a key={button.id} href={button.button_link} target="_blank" rel="noopener noreferrer" className="button-link postTagBtn">
                   <i>  <img src='/icons/link.svg' /> </i>  {button.button_text}
                  </a>
                ))}
              </div>
            </div>
          )}

          {comments.length > 0 && (
            <div className="comments-section">
              <h2>Comments</h2>
              {comments.map((comment, index) => (
                <div key={index} className="comment">
                  <h3>{comment.name}</h3>
                  <p>{comment.comment}</p>
                </div>
              ))}
            </div>
          )}

          <div className="comment-form">
            <h2>Leave a comment</h2>
            <form onSubmit={handleCommentSubmit}>
              <div>
                <label>Name</label>
                <input
                  type="text"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Comment</label>
                <textarea
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogDetail;
