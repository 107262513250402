// utils/transformToDelta.js
export default function transformToDelta(richText) {
  const delta = { ops: [] };

  if (Array.isArray(richText)) {
    richText.forEach(block => {
      if (block.children && block.children.length > 0) {
        if (block.type === 'list') {
          block.children.forEach(item => {
            const attributes = {};
            item.children.forEach(child => {
              if (child.bold) attributes.bold = true;
              if (child.italic) attributes.italic = true;
              if (child.underline) attributes.underline = true;
              if (child.strikethrough) attributes.strike = true;

              delta.ops.push({
                insert: child.text || '',
                attributes: Object.keys(attributes).length ? attributes : undefined
              });
            });

            delta.ops.push({
              insert: '\n',
              attributes: { list: block.format === 'ordered' ? 'ordered' : 'bullet' }
            });
          });
        } else {
          block.children.forEach(child => {
            const attributes = {};
            if (child.bold) attributes.bold = true;
            if (child.italic) attributes.italic = true;
            if (child.underline) attributes.underline = true;
            if (child.strikethrough) attributes.strike = true;

            if (child.type === 'link') {
              delta.ops.push({
                insert: child.children.map(linkChild => linkChild.text || '').join(''),
                attributes: { ...attributes, link: child.url }
              });
            } else {
              delta.ops.push({
                insert: child.text || '',
                attributes: Object.keys(attributes).length ? attributes : undefined
              });
            }
          });

          switch (block.type) {
            case 'heading':
              delta.ops.push({
                insert: '\n',
                attributes: { header: block.level }
              });
              break;
            case 'quote':
              delta.ops.push({
                insert: '\n',
                attributes: { blockquote: true }
              });
              break;
            case 'paragraph':
              delta.ops.push({ insert: '\n' });
              break;
            default:
              delta.ops.push({ insert: '\n' });
          }
        }
      }
    });
  } else {
    delta.ops.push({ insert: richText || '' });
  }

  return delta;
}
