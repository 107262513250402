import React, { useEffect, useState } from 'react';
import './home.css'; // Güncellenen CSS'i içe aktar

const BASE_URL = "https://africastrongcms.twnln.com//";

function Footer() {
  const [footerData, setFooterData] = useState(null);
  const [navData, setNavData] = useState([]); // Nav verisi için state
  const [email, setEmail] = useState('');
  const [openMenus, setOpenMenus] = useState({}); // Alt menüleri kontrol için state
  const [events, setEvents] = useState([]); // Etkinlik verisi için state

  // Footer verisini çek
  useEffect(() => {
    const url = `${BASE_URL}api/footer?populate[comp_one][populate]=logo,button_logo&populate[comp_two][populate]=nav&populate[comp_two][populate]=events&populate[comp_three][populate]=insta_button,linkedin_button,facebook_button,youtube_button`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setFooterData(data.data.attributes);
        console.log(data.data.attributes);
      })
      .catch(error => {
        console.error('fetching error:', error);
      });
  }, []);

  // Nav verisini çek
  useEffect(() => {
    const navUrl = `${BASE_URL}api/nav-menus?populate=*`; // API URL'i

    fetch(navUrl)
      .then(response => response.json())
      .then(data => {
        // Veriyi id'ye göre sıralama işlemi
        const sortedNavData = data.data.sort((a, b) => a.id - b.id);
        setNavData(sortedNavData); // Sıralanmış veriyi state'e kaydet
        console.log("Nav Data:", sortedNavData);
      })
      .catch(error => {
        console.error('Nav fetching error:', error);
      });
  }, []);

  // Etkinlik verisini çek
  useEffect(() => {
    const eventsUrl = `${BASE_URL}api/event?populate=*`; // Etkinlik API URL'i

    fetch(eventsUrl)
      .then(response => response.json())
      .then(data => {
        setEvents(data.data.attributes.comp.map(event => event.title));
        console.log("Events Data:", data.data.attributes.comp);
      })
      .catch(error => {
        console.error('Events fetching error:', error);
      });
  }, []);

  // Menü aç/kapa fonksiyonu
  const toggleMenu = (menuId) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menuId]: !prevOpenMenus[menuId],
    }));
  };

  // Fareyle üzerine gelindiğinde menüyü açma
  const handleMouseEnter = (menuId) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menuId]: true,
    }));
  };

  // Fareyle üzerinden çıkıldığında menüyü kapatma
  const handleMouseLeave = (menuId) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menuId]: false,
    }));
  };

  if (!footerData) return <div></div>;

  const getImageUrl = (data) => {
    const url = data?.attributes?.url ? `${BASE_URL.replace(/\/$/, '')}${data.attributes.url}` : '';
    return url;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      alert('Please enter email address');
      return;
    }

    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address');
      return;
    }

    fetch('https://africastrongcms.twnln.com/api/subscriptions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          email: email, // email with data
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(`Error: ${data.error.message}`);
        } else {
          console.log('E-posta kaydı:', data);
          alert('Subscription Successful!');
          setEmail('');
        }
      })
      .catch((error) => {
        console.error('Cannot Send Mail', error);
        alert('Cannot Send Mail');
      });
  };

  const { comp_one, comp_two, comp_three, copyright } = footerData;

  return (
    <div className='footer'>
      <div className='container'>
        <div className='footer-container'>
          {/* Component One */}
          <div className='footer-comp-one'>
            <div className='comp-one-brand'>
              {comp_one.logo && (
                <a href="/">
                  <img src={getImageUrl(comp_one.logo.data)} alt="Logo" className="footer-logo" />
                </a>
              )}
              <div className='footer-title'>
                <a href='/'>{comp_one.title}</a>
                <div className='navbar-sentence' style={{ color: '#1D2513' }}>
                  {comp_one.sentence}
                </div>
              </div>
            </div>
            <div className='comp-one-mid'>
              <p style={{ marginTop: '43px' }}>{comp_one.adress}</p>
              <p>{comp_one.mail}</p>
            </div>
            {comp_one.button_logo && (
              <div className='donate-button-container'>
                <a href={comp_one.button_link}>
                  <button className='donate-button-footer' style={{ backgroundColor: '#234292', color: '#FFFFFF' }}>
                    <img src={getImageUrl(comp_one.button_logo.data)} alt="Button Logo" className="footer-button-logo" />
                    {comp_one.button}
                  </button>
                </a>
              </div>
            )}
          </div>

          {/* Component Two */}
          <div className='footer-comp-two'>
            <div>
              <ul className='comp-two-nav'>
                {navData
                  .filter(item => item.attributes.isMain) // Sadece isMain: true olanları filtrele
                  .map((mainItem, index) => (
                    <li key={index}
                      onMouseEnter={() => handleMouseEnter(mainItem.id)}
                      onMouseLeave={() => handleMouseLeave(mainItem.id)}
                      style={{ position: 'relative' }}>
                      <div style={{ cursor: 'pointer' }}>
                        <a 
                          href={mainItem.attributes.slugName ? `/${mainItem.attributes.slugName}` : 'javascript:void(0)'}
                          className={mainItem.attributes.slugName ? '' : 'footerTitleNav'}
                        >
                          {mainItem.attributes.MenuName}
                        </a>
                     
                      </div>

                      {/* Alt menüleri göster */}
         
                        <ul className="submenu">
                          {mainItem.attributes.nav_menus.data.map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              style={{
                                marginBottom: subIndex === mainItem.attributes.nav_menus.data.length - 1 ? '-15px' : '0',
                              }}
                            >
                              <a href={`/${subItem.attributes.slugName}`}>{subItem.attributes.MenuName}</a>
                            </li>
                          ))}
                        </ul>
                     

                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <ul className='comp-two-event'>
                <li>Lastest Events</li>
                {events.length > 0 ? (
                  events.map((event, index) => (
                    <a href='https://africastrong.twnln.com/events/'> <li key={index}>{event}</li></a>
                  ))
                ) : (
                  <li></li> // Veri yoksa gösterilecek alternatif içerik
                )}
              </ul>
            </div>
          </div>

          {/* Component Three */}
          <div className='footer-comp-three'>
            <h3>{comp_three.title}</h3>
            <p>{comp_three.text_one}</p>

            {/* Email Input Form */}
            <form onSubmit={handleSubmit}>
              <div className='subs'>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="E-mail "
                />
                <button type="submit">Send</button>
              </div>
            </form>

            <p>{comp_three.text_two}</p>

            <div className='social-icons'>
              {comp_three.insta_button && (
                <a className='social-icons-item' target="_blank" href="https://www.instagram.com/africastronginc/">
                  <img style={{ width: '18.33px' }} src={getImageUrl(comp_three.insta_button.data)} alt="Instagram" />
                </a>
              )}
              {comp_three.linkedin_button && (
                <a className='social-icons-item' target="_blank" href="https://www.linkedin.com/company/africastronginc">
                  <img style={{ width: '17.11px' }} src={getImageUrl(comp_three.linkedin_button.data)} alt="LinkedIn" />
                </a>
              )}
              {comp_three.facebook_button && (
                <a className='social-icons-item' target="_blank" href="https://www.facebook.com/africastronginc">
                  <img style={{ width: '8.56px', height: '15.89px' }} src={getImageUrl(comp_three.facebook_button.data)} alt="Facebook" />
                </a>
              )}
              {comp_three.youtube_button && (
                <a className='social-icons-item' target="_blank" href="https://www.youtube.com/channel/UCszbJKn1--LsF8XIo0rCulw">
                  <img style={{ width: '20px', height: '14px' }} src={getImageUrl(comp_three.youtube_button.data)} alt="YouTube" />
                </a>
              )}
            </div>
          </div>
        </div>
        {/* Copyright */}
        <div className='footer-copyright'>
          <p>{copyright}</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
