// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_popup__-Oq6M {
    position: fixed;
    inset: 0px;
    background: hsl(0deg 0% 0% / 70%);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.styles_popupContent__\\+5uI\\+ {
    position: relative;
    display: flex;
    flex-direction: column;
}

.styles_popupClose__-7xzq {
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    background: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: -20px;
    top: -20px;
    cursor: pointer;
    padding: 8px;
    box-sizing: border-box;
}

.styles_popupClose__-7xzq>svg {
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Popup/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,iCAAiC;IACjC,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,eAAe;IACf,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".popup {\r\n    position: fixed;\r\n    inset: 0px;\r\n    background: hsl(0deg 0% 0% / 70%);\r\n    z-index: 999;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.popupContent {\r\n    position: relative;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.popupClose {\r\n    position: absolute;\r\n    display: flex;\r\n    width: 40px;\r\n    height: 40px;\r\n    background: #fff;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border-radius: 50%;\r\n    right: -20px;\r\n    top: -20px;\r\n    cursor: pointer;\r\n    padding: 8px;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.popupClose>svg {\r\n    width: 100%;\r\n    height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `styles_popup__-Oq6M`,
	"popupContent": `styles_popupContent__+5uI+`,
	"popupClose": `styles_popupClose__-7xzq`
};
export default ___CSS_LOADER_EXPORT___;
