
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./home";
import Navbar from "./navbar";  
import About from "./about";
import Blog from "./blog";
import  Footer  from './footer';
import Event from './event';
import Program from './program';
import Team from "./our-team";
import Contact from './contact';
import BlogDetail from './blogdetail';
import Projects from './projects';
import ProjectDetails from './projectdetails';


function App() {
 
var metaViewport = document.createElement('meta');
metaViewport.name = 'viewport';
metaViewport.content = 'width=device-width, initial-scale=1.0';


document.getElementsByTagName('head')[0].appendChild(metaViewport);

  return (
    <Router>
      <Navbar /> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/homepage" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/our-team" element={<Team/>} />
        <Route path="/Project" element={<Projects/>} />
        <Route path="/project/:slug" element={<ProjectDetails/>} /> 
        <Route path="/Projects" element={<Projects/>} />
        <Route path="/projects/:slug" element={<ProjectDetails/>} /> 
        <Route path="/event" element={<Event />} />
        <Route path="/events" element={<Event />} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/blog/:slug" element={<BlogDetail/>} />
        <Route path="/blogs" element={<Blog/>} />
        <Route path="/blogs/:slug" element={<BlogDetail/>} />
        <Route path="/programs" element={<Program/>} />
        <Route path="/programs" element={<Program/>} />
        <Route path="/contact" element={<Contact/>} />
        
      </Routes>
       <Footer/> 
    </Router>
  );
}

export default App;
