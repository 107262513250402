import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './project-details.css'; // CSS dosyasını içe aktar
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import transformToDelta from './utils/transformToDelta';

function ProjectDetails() {
  const { slug } = useParams();
  const [error, setError] = useState('');
  const [projectData, setProjectData] = useState(null);
  const [projectId, setProjectId] = useState(null);

  // URL kodlamasını çöz
  const decodeSlug = (encodedSlug) => decodeURIComponent(encodedSlug);

  // Tüm projeleri çekme ve slug ile id eşleştirme
  const fetchProjectIdBySlug = async () => {
    try {
      const response = await axios.get('https://africastrongcms.twnln.com/api/projects');
      const projects = response.data?.data || [];

      // URL'den gelen slug'ı proje slug'ları ile eşleştir
      const decodedSlug = decodeSlug(slug);
      console.log('Decoded slug:', decodedSlug); // Konsola decode edilmiş slug'ı yazdır

      const matchedProject = projects.find(project => decodeSlug(project.attributes.slug) === decodedSlug);

      if (matchedProject) {
        setProjectId(matchedProject.id);
      } else {
        setError('Proje bulunamadı.');
        console.log('Mevcut projeler:', projects); // Konsola mevcut projeleri yazdır
      }
    } catch (err) {
      setError('Proje ID çekilirken bir hata oluştu.');
      console.error(err);
    }
  };

  // Proje verilerini getiren fonksiyon
  const fetchProjectDetail = async () => {
    if (!projectId) return;

    try {
      const response = await axios.get(`https://africastrongcms.twnln.com/api/projects/${projectId}?populate=*`);
      setProjectData(response.data?.data);
    } catch (err) {
      setError('Proje verisi çekilirken bir hata oluştu.');
      console.error(err);
    }
  };

  useEffect(() => {
    fetchProjectIdBySlug();
  }, [slug]);

  useEffect(() => {
    if (projectId) {
      fetchProjectDetail();
    }
  }, [projectId]);

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!projectData) {
    return <span class="loader"></span>;
  }

  const { attributes } = projectData;
  const mediaData = attributes?.media?.data;
  const youtubeVideoLink = attributes?.youtube_video_link; // Burada video bağlantısını alıyoruz

  return (
    <>

      <div className='headerBanner'>
        <div className='container'>
          <h1> {attributes?.title} </h1>

          <ul class="breadcrumb">
            <li><a href="/projects">Projects</a></li>
            <li>{attributes?.title}</li>
          </ul>

        </div>
      </div>

      <div className='container'>
        <div className="project-detail-container">

          {youtubeVideoLink && (
            <div className="video-container">
              <iframe
                src={youtubeVideoLink}
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          )}


          <div
            dangerouslySetInnerHTML={{ __html: attributes?.ParagraphEditor }}
          />


          {mediaData && (
            <div className="media-container">
              {Array.isArray(mediaData) ? (
                mediaData.map((media) => (
                  <div key={media.id}>
                    <img
                      className="photo"
                      src={`https://africastrongcms.twnln.com/${media.attributes.url}`}
                      alt={media.attributes.alternativeText || 'Medya'}
                    />
                  </div>
                ))
              ) : (
                <img
                  className="photo"
                  src={`https://africastrongcms.twnln.com/${mediaData.attributes.url}`}
                  alt={mediaData.attributes.alternativeText || 'Medya'}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProjectDetails;
