import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './blog.css';  // CSS dosyasını dahil ediyoruz

function MainBlog() {
  const [error, setError] = useState('');
  const [compData, setCompData] = useState([]);
  const navigate = useNavigate();  // useNavigate hook'unu kullanıyoruz

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://africastrongcms.twnln.com/api/blogs?populate[comp][populate]=*');
      let compItems = response.data?.data || [];

      // Sadece isMain true olan blogları filtrele
      compItems = compItems.filter(blog => blog.attributes.isMain === true);

      // ID'ye göre sıralama
      compItems = compItems.sort((a, b) => a.id - b.id);

      setCompData(compItems);
    } catch (err) {
      setError('fetching error.');
      console.error(err);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const getFirstText = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.innerText || tempDiv.textContent;
  
    return textContent.split(' ').slice(0, 15).join(' ') + '...';
  };

  const handleNavigation = (slug) => {
    navigate(`/blog/${encodeURIComponent(slug)}`);
  };

  return (
    <div className="container">
      <div className="blog-container">
        {error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
          compData.length ? (
            compData.map(blog => {
              const comp = blog.attributes.comp[0];
              const blogDate = comp?.date || 'Date not available';

              // paragraph öğesinden ilk 10 kelimesi
              const pOneText = comp?.ParagraphEditor && getFirstText(comp.ParagraphEditor);

              const slug = blog.attributes.slug || '';

              return (
                <div key={blog.id} className="blog-item">
                  {comp?.media?.data && (
                    <img
                      src={`https://africastrongcms.twnln.com/${comp.media.data.attributes.url}`}
                      alt="Media"
                      className="blog-image"
                    />
                  )}

                  <div className='p_comp'>
                    <h1 onClick={() => handleNavigation(slug)} style={{ cursor: 'pointer' }}>
                      {blog.attributes.title}
                    </h1>
                    <p>{pOneText}</p>
                  </div>
                  <div className="blog-footer">
                    <span className="date">
                      <img
                        className='date-image'
                        style={{ width: '16px', height: '19px', marginRight: '10px' }}
                        alt=''
                        src='https://africastrongcms.twnln.com//uploads/date_cca29d7726.png'
                      />
                      {blogDate}
                    </span>
                    <a onClick={() => handleNavigation(slug)} >
                      Read More...

                    </a>
                  </div>
                </div>
              );
            })
          ) : (
            <span className="loader"></span>
          )
        )}
      </div>
    </div>
  );
}

export default MainBlog;
