import { useEffect, useState } from "react";

const Map = ({ lat, lng }) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    // Daha önce yüklenmiş bir Google Maps script'i olup olmadığını kontrol et
    const existingScript = document.getElementById("googleMaps");

    if (!existingScript) {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBI61ldOKhjSPdG922_KGBQmKkYILzYdMs`;
      googleMapsScript.id = "googleMaps";
      document.body.appendChild(googleMapsScript);

      googleMapsScript.onload = () => {
        initMap();
      };
    } else {
      if (window.google) {
        initMap();
      } else {
        existingScript.onload = () => {
          initMap();
        };
      }
    }

    function initMap() {
      const googleMaps = window.google.maps;
      const map = new googleMaps.Map(document.getElementById("map"), {
        center: { lat: lat, lng: lng },
        zoom: 10,
      });

      new googleMaps.Marker({
        position: { lat: lat, lng: lng },
        map: map,
      });

      setMap(map);
    }
  }, [lat, lng]);

  return <div id="map"></div>;
};

export default Map;
